import { Component } from 'react';

import GA from '@/analytics/ga/components/GA';
import { Footer as FooterEvent } from '@/analytics/ga/index';
import Logo from '@/components/core/Logo/Logo';

import styles from './MainFooter.module.scss';

import { SHOP_LINK, SOCIAL_LINKS } from '@/utils/constants';
import { withTranslation, WithTranslation } from 'next-i18next';
import Link from 'next/link';
import { useUser } from '@/hooks/useUser';
import { withUser } from '@/components/shared/utilities/WithUser/WithUser';

export interface IFooterProps extends WithTranslation, ReturnType<typeof useUser> {}

class Footer extends Component<IFooterProps> {
	render() {
		const { user, t } = this.props;

		return (
			<div className={styles.footer}>
				<div className={styles.footerContainer}>
					<div className={styles.footerLogo}>
						<Logo className={styles.footerLogoImage} />
					</div>
					<div className={styles.footerLinks}>
						<div className={styles.footerList}>
							<div className={styles.footerListTitle}>{t('museum')}</div>
							<ul className={styles.footerListContent}>
								<li className={styles.footerListItem}>
									<GA event={FooterEvent.visit}>
										<Link href="/visit/museum-details" className={styles.footerListLink}>
											{t('visit')}
										</Link>
									</GA>
								</li>
								<li className={styles.footerListItem}>
									<Link href="/visit" className={styles.footerListLink}>
										{t('on-tour')}
									</Link>
								</li>
								<li className={styles.footerListItem}>
									<GA event={FooterEvent.explore}>
										<Link href="/explore" className={styles.footerListLink}>
											{t('explore')}
										</Link>
									</GA>
								</li>
								<li className={styles.footerListItem}>
									<a href={SHOP_LINK} target="_blank" className={styles.footerListLink}>
										{t('shop')}
									</a>
								</li>
							</ul>
						</div>
						<div className={styles.footerList}>
							<div className={styles.footerListTitle}>{t('share')}</div>
							<ul className={styles.footerListContent}>
								<li className={styles.footerListItem}>
									<GA event={FooterEvent.share}>
										<Link href="/share" className={styles.footerListLink}>
											{t('stories')}
										</Link>
									</GA>
								</li>
								{user ? (
									<li className={styles.footerListItem}>
										<GA event={FooterEvent.addStory}>
											<Link href={{ query: { open: 'share-story' } }} shallow={true} className={styles.footerListLink}>
												{t('share-a-story')}
											</Link>
										</GA>
									</li>
								) : null}
							</ul>
						</div>
						<div className={styles.footerList}>
							<div className={styles.footerListTitle}>{t('join')}</div>
							<ul className={styles.footerListContent}>
								<li className={styles.footerListItem}>
									<GA event={FooterEvent.donate}>
										<Link href={{ query: { open: 'contribute' } }} shallow={true} className={styles.footerListLink}>
											{t('contribute')}
										</Link>
									</GA>
								</li>
								{user ? (
									<li className={styles.footerListItem}>
										<GA event={FooterEvent.profile}>
											<Link href="/my-profile" className={styles.footerListLink}>
												{t('my-profile')}
											</Link>
										</GA>
									</li>
								) : (
									<li className={styles.footerListItem}>
										<Link href={{ query: { open: 'register' } }} shallow={true} className={styles.footerListLink}>
											{t('register')}
										</Link>
									</li>
								)}
								<li className={styles.footerListItem}>
									<Link href={{ query: { open: 'faq' } }} shallow={true} className={styles.footerListLink}>
										{t('faq')}
									</Link>
								</li>
								<li className={styles.footerListItem}>
									<Link href={{ query: { open: 'contact' } }} shallow={true} className={styles.footerListLink}>
										{t('contact-us')}
									</Link>
								</li>
							</ul>
						</div>
						<div className={styles.footerList}>
							<div className={styles.footerListTitle}>{t('press')}</div>
							<ul className={styles.footerListContent}>
								<li className={styles.footerListItem}>
									<Link href={{ query: { open: 'in-press' } }} shallow={true} className={styles.footerListLink}>
										{t('in-press')}
									</Link>
								</li>
								<li className={styles.footerListItem}>
									<Link href={{ query: { open: 'press-kit' } }} shallow={true} className={styles.footerListLink}>
										{t('press-kit')}
									</Link>
								</li>
							</ul>
						</div>
						<div className={styles.footerList}>
							<div className={styles.footerListTitle}>{t('follow')}</div>
							<ul className={styles.footerListContent}>
								{SOCIAL_LINKS.map((item) => {
									return (
										<li className={styles.footerListItem} key={`${item.id}-footer`}>
											<a href={item.link} target="_blank" className={styles.footerListLink}>
												{item.name}
											</a>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
				<div className={styles.footerTrademark}>{t('museum-of-broken-relationships-trademark')}</div>
			</div>
		);
	}
}

export default withTranslation()(withUser(Footer));
